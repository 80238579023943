<template>
   <autocomplete ref="autocomplete" :search="search" :debounce-time="1000" :submitOnEnter="true" :get-result-value="r => r.codigo" @submit="submit" placeholder="Buscar por CFOP ou descrição">
      <template #result="{ result, props }">
         <li v-bind="props">
            <h1 class="font-13 mb-1 limitador-1 text-capitalize">{{ result.codigo }}</h1>
            <p class="font-10 mb-0 opacity-90 text-capitalize">
               <i class="far fa-exchange-alt font-9 me-1"></i>{{ String(result.tipo).toLowerCase() }}<small class="mx-2">/</small>
               <i class="far fa-map-marker-alt font-8 me-1"></i>{{ String(result.estado).toLowerCase() }}
            </p>
            <p class="font-10 mb-0 opacity-90">{{ result.descricao }}</p>
         </li>
      </template>
   </autocomplete>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'AutoCompleteCFOP',
   props: ['mesmoEstado', 'apenasEntrada'],
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
      search : function (input) {
         if (input.length < 3) return []

         return this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/searchCFOPs',
            headers: {'Content-Type': 'application/json'},
            data: {'nome': input, 'mesmoEstado': this.mesmoEstado, 'apenasEntrada': this.apenasEntrada}

			}).then(response => {
            return response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}

            return []
			})
      },
      submit : function (result) {
         this.$emit('submit', result.codigo)
      }
   }
}

</script>
<template>
   <autocomplete ref="autocomplete" :search="search" :debounce-time="1000" @submit="submit" :submitOnEnter="true" :get-result-value="r => r.nome" placeholder="Busque por nome do produto, código ou família">
      <template #result="{ result, props }">
         <li v-bind="props">
            <div class="row align-items-center">
               <div class="w-max-content pe-12">
                  <img :src="result.banner == null ? '' : result.banner" class="rounded-1" alt="icon" @error="imageError" height="30" />
               </div>
               <div class="col">
                  <h1 class="font-13 mb-1 limitador-1 text-capitalize">{{ String(result.nome).toLowerCase() }}</h1>
                  <p class="font-10 mb-0 limitador-1 opacity-90">
                     <span><i class="far fa-barcode font-9 me-1"></i>{{ result.codigos.filter(e => e.ean != null).length == 0 ? 'Sem código' : String(result.codigos.filter(e => e.ean != null).map(e => e.ean)).replace(/,/g, ", ") }}</span>
                  </p>
               </div>
            </div>
         </li>
      </template>
   </autocomplete>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'AutoCompleteProduto',
   props: ['loja'],
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      search : function (input) {
         if (input.length < 3) return []

         return this.$axios({
				method: 'post',
				url: this.urlRest +'produtos/searchPrecos',
            headers: {'Content-Type': 'application/json'},
            data: {'nome': input, 'loja': this.loja}

			}).then(response => {
            return response.data.resultado

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}

            return []
			})
      },
      submit : function (result) {
         this.$emit('submit', result)
      }
   }
}

</script>
<template>
   <autocomplete ref="autocomplete" :search="search" :debounce-time="1000" @submit="submit" :get-result-value="r => r.chave" :submitOnEnter="true" placeholder="Busque por chave da nota, razão social, nome ou CNPJ do emitente">
      <template #result="{ result, props }">
         <li v-bind="props">
            <div class="row align-items-center">
               <div class="col-12"><h1 class="font-13 mb-1 limitador-2">{{ result.chave }}</h1></div>
               <div class="col">
                  <p class="font-10 mb-0 limitador-1 opacity-90">
                     <i class="far fa-calendar font-9 me-1"></i>
                     <span> {{ result.dataNota.dayOfMonth.toString().padStart(2, '0') +'/'+ result.dataNota.monthValue.toString().padStart(2, '0') +'/'+ result.dataNota.year }}</span>
                     <span> {{ result.dataNota.hour.toString().padStart(2, '0') +':'+ result.dataNota.minute.toString().padStart(2, '0') }}</span>
                  </p>
               </div>
               <div class="col-6 d-none d-xxl-block">
                  <p class="font-10 mb-0 limitador-1 opacity-90">
                     <i class="far fa-money-bill-wave-alt font-9 me-1"></i>
                     <span><small>R$ </small>{{ parseFloat(result.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                  </p>
               </div>
            </div>
         </li>
      </template>
   </autocomplete>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'AutoCompleteNota',
   props: ['modelo'],
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
      search : function (input) {
         if (input.length < 3) return []

         return this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/searchNotas',
            headers: {'Content-Type': 'application/json'},
            data: {'status': null, 'loja': null, 'nome': input, 'data': [], 'modelo': this.modelo}

			}).then(response => {
            return response.data.resultado

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}

            return []
			})
      },
      submit : function (result) {
         this.$emit('submit', result)
      }
   }
}

</script>
<template>
   <autocomplete ref="autocomplete" :search="search" :debounce-time="1000" :submitOnEnter="true" :get-result-value="r => r.cnpj" @submit="submit" :placeholder="'Buscar '+ String(tipo).toLowerCase()">
      <template #result="{ result, props }">
         <li v-bind="props">
            <h1 class="font-13 mb-1 limitador-2 text-capitalize">{{ result.nomeFantasia != null ? String(result.nomeFantasia).toLowerCase() : String(result.razaoSocial).toLowerCase() }}</h1>
            <p class="font-10 mb-0 limitador-1 opacity-90">
               <span><i class="far fa-address-card font-9 me-1"></i>{{ result.cpfCnpj }}</span>
            </p>
         </li>
      </template>
   </autocomplete>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'AutoCompleteDestinatario',
   props: ['tipo'],
   computed: {
      ... mapState({
         urlRest: state => state.urlRest
      })
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      search : function (input) {
         if (input.length < 3) return []

         return this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/searchDestinatarios',
            headers: {'Content-Type': 'application/json'},
            data: {'tipo': this.tipo, 'nome': input}

			}).then(response => {
            return response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}

            return []
			})
      },
      submit : function (result) {
         this.$emit('submit', result)
      }
   }
}

</script>
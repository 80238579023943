<template>
   <div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Preencha os dados do destinatário, emitente e produtos, valide as informações fiscais, e emita uma nota fiscal eletrônica.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div id="emissao-tab">
			<div class="row">
				<div id="search-nav" class="col-12 px-0 d-block btn-group dropup">
					<button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
						<i class="far fa-file font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
					</button>
					<div class="card mb-1 dropdown-menu dropdown-menu-left">
						<div class="card-body p-3 pb-1">
							<div class="row">
								<div class="mb-3 mb-sm-0 mb-lg-3" :class="pesquisa.naturezaOperacao != null ? 'col-sm-6 col-lg-12' : 'col-12'">
									<label><i class="far fa-stream color-theme font-11 me-1"></i> Operação *</label>
									<v-select name="Desktop" :options="listaOperacoesNfe" v-model="pesquisa.naturezaOperacao" label="naturezaOperacao" :clearable="false" placeholder="Selecione uma opção" 
										@input="salvarTemp" :disabled="pesquisa.naturezaOperacao != null">
										<template #option="option">
											<span class="limitador-2 text-wrap lh-sm mt-1">{{ option.naturezaOperacao }}</span>
											<small class="d-block lh-sm mb-1 opacity-90 font-11"><i class="far fa-exchange-alt font-9 me-1"></i>{{ option.operacao }}</small>
										</template>
										<template v-slot:no-options>Sem resultados</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.naturezaOperacao" @change="salvarTemp" :disabled="pesquisa.naturezaOperacao != null">
										<option v-for="(option, index) in listaOperacoesNfe" :key="index" :value="option.operacao">{{ option.operacao }}</option>
									</select>
								</div>
								<div class="col-sm-6 col-lg-12" v-if="pesquisa.naturezaOperacao != null">
									<label><i class="far fa-envelope color-theme font-10 me-1"></i> Destinatário *</label>
									<div class="input-group">
										<div class="input-group-text">
											<i class="fal cursor-pointer" :class="config.searchDestino ? 'fa-undo' : 'fa-search'" @click="config.searchDestino = !config.searchDestino"
												:title="config.searchDestino ? 'Voltar' : 'Buscar'"></i>
										</div>
										<input v-if="!config.searchDestino" @click="config.searchDestino = true;" :value="pesquisa.cpfCnpjDestinatario" title="Buscar destinatário" 
											type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção" />
										<autoCompleteDestinatario v-else class="col" :tipo="pesquisa.naturezaOperacao.tipoDestinatario" @submit="setDestinatarioTemp($event)" />
									</div>
								</div>
								<div class="col-12 my-1" v-if="pesquisa.naturezaOperacao != null"><hr></div>
								<div class="col-sm-6 col-lg-12 mb-3" v-if="pesquisa.naturezaOperacao != null">
									<label><i class="far fa-store color-theme font-10 me-1"></i> Loja emitente *</label>
									<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="pesquisa.idLojaSaida" label="nomeFantasia" :reduce="loja => loja.id" placeholder="Selecione uma opção"
										@input="buscarEstoques(pesquisa.idLojaSaida)">
										<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.idLojaSaida" @change="buscarEstoques(pesquisa.idLojaSaida)">
										<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
									</select>
								</div>
								<div class="col-sm-6 col-lg-12 mb-3" v-if="pesquisa.naturezaOperacao != null">
									<label><i class="far fa-box color-theme font-10 me-1"></i> Estoque saída *</label>
									<v-select name="Desktop" :options="config.estoques" v-model="pesquisa.idEstoqueSaida" label="nome" :reduce="e => e.id" placeholder="Selecione uma opção" @input="salvarTemp">
										<template v-slot:no-options>
											<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Utilize a tela "Estoque" > "Gerenciamento" para adicionar</small>
										</template>
									</v-select>
									<select name="Mobile" class="form-control" v-model="pesquisa.idEstoqueSaida" @change="salvarTemp">
										<option v-for="(option, index) in config.estoques" :key="index" :value="option.id">{{ option.nome }}</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-0">
					<div class="card mb-1">
						<div class="card-body py-12">
							<div class="row align-items-center">
								<div class="col-6 col-md-3 col-lg-6">
									<strong class="subtitle font-14 me-1">Total</strong>
								</div>
								<div class="col-6 col-md-3 col-lg-6 text-end text-md-start text-lg-end">
									<span class="font-15"><small>R$ </small>{{ parseFloat(pesquisa.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
								</div>
								<div class="col ps-0 pe-1 mt-2 mt-md-0 mt-lg-2" @click="excluirTemp" v-if="pesquisa.naturezaOperacao != null">
									<button class="btn border-red text-red"><i class="far fa-times me-1"></i> Descartar</button>
								</div>
								<div class="col-6 col-md-3 col-lg-6 px-0 mt-2 mt-md-0 mt-lg-2" @click="emitirNota" v-if="pesquisa.produtos.length > 0">
									<button class="btn border-green text-green"><i class="fal fa-file-invoice me-1 font-12"></i> Emitir</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-lg px-lg-0">
			<div class="row">
				<div class="col-12 px-0" v-if="pesquisa.idLojaSaida && pesquisa.naturezaOperacao && pesquisa.cpfCnpjDestinatario">
					<div class="card mb-1">
						<div class="card-body p-3 pb-0">
							<div class="row">
								<div class="col-sm mb-3" v-if="pesquisa.naturezaOperacao.finalidade != 'Devolução'">
									<label><i class="far fa-apple-alt color-theme font-11 me-1"></i> Adicionar produto</label>
									<autoCompleteProduto :loja="pesquisa.idLojaSaida" @submit="setProdutoTemp($event)" />
								</div>
								<div class="w-max-content align-self-end mb-3 d-none d-sm-block" v-if="pesquisa.naturezaOperacao.finalidade != 'Devolução'">
									<i class="fal fa-grip-lines-vertical color-theme font-20 m-2"></i>
								</div>
								<div class="col-sm mb-3">
									<label>
										<i class="far fa-file color-theme font-11 me-1"></i> Adicionar nota 
										<i class="far fa-barcode color-theme ms-2 cursor-pointer float-end" title="Chaves referenciadas" @click="verChavesReferenciadas" v-if="pesquisa.chaveReferenciada != null"></i>
									</label>
									<autoCompleteNota @submit="addNotaTemp($event)" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 px-0 text-center" v-if="pesquisa.produtos.length == 0">
					<div class="card">
						<div class="card-body py-5" v-if="pesquisa.naturezaOperacao == null">
							<i class="fal fa-stream font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-14 mt-4 mb-0 text-secondary text-uppercase">1º Passo</h4>
							<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Selecione uma tipo de operação</h4>
						</div>
						<div class="card-body py-5" v-else-if="pesquisa.cpfCnpjDestinatario == null">
							<i class="fal fa-envelope font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-14 mt-4 mb-0 text-secondary text-uppercase">2º Passo</h4>
							<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Selecione um destinatário</h4>
						</div>
						<div class="card-body py-5" v-else-if="pesquisa.idLojaSaida == null">
							<i class="fal fa-store font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-14 mt-4 mb-0 text-secondary text-uppercase">3º Passo</h4>
							<h4 class="font-12 mt-2 mb-0 text-secondary text-uppercase">Selecione uma loja</h4>
						</div>
						<div class="card-body py-5" v-else>
							<i class="fal fa-box-open font-60 text-secondary opacity-50 d-block mb-3"></i>
							<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum produto encontrado</h4>
						</div>
					</div>
				</div>
				<div class="col-12 px-0" v-else>
					<produto v-for="(produto, index) in pesquisa.produtos" :key="index" :produto="produto" :index="index" 
						@salvar="salvarProdutoTemp($event, false)" @editar="abrirModalProduto($event)" @remover="excluirProdutoTemp($event)" />
				</div>
			</div>
		</div>

		<!-- modalProduto -->
      <div class="modal fade" id="modalProduto" tabindex="-1" aria-labelledby="modalProdutoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalProdutoLabel">Editar produto ({{ modal.nome }})</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 px-1 active" id="pills-produto-tab" data-bs-toggle="pill" data-bs-target="#pills-produto" type="button" role="tab" aria-controls="pills-produto" aria-selected="true">
											<i class="far fa-apple-alt d-none d-md-inline me-1 font-12"></i><span> Produto</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 px-1" id="pills-icms-tab" data-bs-toggle="pill" data-bs-target="#pills-icms" type="button" role="tab" aria-controls="pills-icms" aria-selected="false">
											<i class="far fa-percentage d-none d-md-inline me-1"></i><span> ICMS</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 px-0" id="pills-pisCofins-tab" data-bs-toggle="pill" data-bs-target="#pills-pisCofins" type="button" role="tab" aria-controls="pills-pisCofins" aria-selected="false">
											<i class="far fa-calculator d-none d-md-inline me-1 font-11"></i><span class="font-12"> PIS/COFINS</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation" v-if="dadosUsuario.tipoEmpresa != 'Simples nacional'">
										<button class="nav-link m-0 px-1" id="pills-ipi-tab" data-bs-toggle="pill" data-bs-target="#pills-ipi" type="button" role="tab" aria-controls="pills-ipi" aria-selected="false">
											<i class="far fa-industry d-none d-md-inline me-1 font-12"></i><span> IPI</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-produto" role="tabpanel" aria-labelledby="pills-produto-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-barcode color-theme font-10 me-1"></i> Código *</label>
												<v-select name="Desktop" :options="modal.codigos" v-model="modal.codigo" :clearable="false" placeholder="Selecione uma opção">
													<template v-slot:no-options>Sem resultados</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.codigo">
													<option v-for="(option, index) in modal.codigos" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Valor desconto *</label>
												<input type="text" class="form-control text-end" v-model="modal.valorDesconto" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-calculator color-theme font-10 me-1"></i> NCM *</label>
												<div class="input-group">
													<div class="input-group-text">
														<i class="fal cursor-pointer" :class="modal.searchNCM ? 'fa-undo' : 'fa-search'" @click="modal.searchNCM = !modal.searchNCM"
															:title="modal.searchNCM ? 'Voltar' : 'Buscar'"></i>
													</div>
													<input v-if="!modal.searchNCM" @click="modal.searchNCM = true;" :value="modal.ncm" title="Buscar NCM" 
														type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção">
													<autoCompleteNCM v-else class="col" @submit="modal.ncm = $event; modal.searchNCM = false;" />
												</div>
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-calculator color-theme font-10 me-1"></i> Origem *</label>
												<v-select :options="listaOrigem" v-model="modal.origem" label="origem" :reduce="o => o.origem" :searchable="false" :clearable="false">
													<template #option="{ origem, descricao }"><strong class="weight-500">{{ origem }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
													<template v-slot:no-options>Sem resultados</template>
												</v-select>
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-calculator color-theme font-10 me-1"></i> CEST</label>
												<input type="text" class="form-control text-end" v-model="modal.cest" maxlength="200"/>
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> CFOP *</label>
												<div class="input-group">
													<div class="input-group-text">
														<i class="fal cursor-pointer" :class="modal.searchCFOP ? 'fa-undo' : 'fa-search'" @click="modal.searchCFOP = !modal.searchCFOP"
															:title="modal.searchCFOP ? 'Voltar' : 'Buscar'"></i>
													</div>
													<input v-if="!modal.searchCFOP" @click="modal.searchCFOP = true;" :value="modal.cfop" title="Buscar CFOP" 
														type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção">
													<autoCompleteCFOP v-else class="col" :apenasEntrada="pesquisa.naturezaOperacao ? pesquisa.naturezaOperacao.operacao : null" 
														:mesmoEstado="!pesquisa.operacaoInterestadual" @submit="modal.cfop = $event; modal.searchCFOP = false;" />
												</div>
											</div>
											<div class="col-sm-6">
												<label><i class="far fa-qrcode color-theme font-11 me-1"></i> Cód. Benefício fiscal</label>
												<input type="text" class="form-control" v-model="modal.codBeneficioFiscal" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-icms" role="tabpanel" aria-labelledby="pills-icms-tab">
								<div class="card mb-1">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-hashtag color-theme font-10 me-1"></i> {{ dadosUsuario.tipoEmpresa == 'Simples nacional' ? 'CSOSN' : 'CST' }} *</label>
												<v-select :options="listaCSOSN" v-model="modal.csosn" label="csosn" :reduce="o => o.csosn" :searchable="false" :clearable="false">
													<template #option="{ csosn, descricao }"><strong class="weight-500">{{ csosn }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
													<template v-slot:no-options>Sem resultados</template>
												</v-select>
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-percentage color-theme me-1"></i> Alíquota *</label>
												<input type="text" class="form-control text-end" v-model="modal.aliqIcms" />
											</div>
											<div class="col-6 mb-3 mb-sm-0">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Base de cálculo *</label>
												<input type="text" class="form-control text-end" v-model="modal.bcIcms" />
											</div>
											<div class="col-sm-6">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Valor *</label>
												<input type="text" class="form-control text-end" v-model="modal.valorIcms" />
											</div>
										</div>
										<div class="row align-items-center" v-if="modal.csosn == '10' || modal.csosn == '30' || modal.csosn == '70' || modal.csosn == '90'">
											<div class="col mt-3 mb-12"><hr class="mt-0 mb-1"></div>
											<div class="w-max-content mt-3 mb-12"><label>Substituição tribitária</label></div>
											<div class="col mt-3 mb-12"><hr class="mt-0 mb-1"></div>
										</div>
										<div class="row" v-if="modal.csosn == '10' || modal.csosn == '30' || modal.csosn == '70' || modal.csosn == '90'">
											<div class="col-6 mb-3">
												<label><i class="far fa-percentage color-theme me-1"></i> Alíquota ST</label>
												<input type="text" class="form-control text-end" v-model="modal.aliqIcmsSt" />
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Base de cálculo ST</label>
												<input type="text" class="form-control text-end" v-model="modal.bcIcmsSt" />
											</div>
											<div class="col-sm-6">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Valor ST</label>
												<input type="text" class="form-control text-end" v-model="modal.valorIcmsSt" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-pisCofins" role="tabpanel" aria-labelledby="pills-pisCofins-tab">
								<div class="card">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-hashtag color-theme font-10 me-1"></i> CST *</label>
												<v-select :options="listaCstPisCofins" v-model="modal.cstPisCofins" label="cst" :reduce="o => o.cst" :searchable="false" :clearable="false">
													<template #option="{ cst, descricao }"><strong class="weight-500">{{ cst }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
													<template v-slot:no-options>Sem resultados</template>
												</v-select>
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-percentage color-theme me-1"></i> Alíquota PIS *</label>
												<input type="text" class="form-control text-end" v-model="modal.aliqPis" />
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Base cálculo PIS *</label>
												<input type="text" class="form-control text-end" v-model="modal.bcPis" />
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Valor PIS *</label>
												<input type="text" class="form-control text-end" v-model="modal.valorPis" />
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-percentage color-theme me-1"></i> Alíquota COFINS *</label>
												<input type="text" class="form-control text-end" v-model="modal.aliqCofins" />
											</div>
											<div class="col-6">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Base cálculo COFINS *</label>
												<input type="text" class="form-control text-end" v-model="modal.bcCofins" />
											</div>
											<div class="col-6">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Valor COFINS *</label>
												<input type="text" class="form-control text-end" v-model="modal.valorCofins" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-ipi" role="tabpanel" aria-labelledby="pills-ipi-tab" v-if="dadosUsuario.tipoEmpresa != 'Simples nacional'">
								<div class="card mb-1">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-hashtag color-theme font-10 me-1"></i> CST</label>
												<v-select :options="listaCstIpi" v-model="modal.cstIPI" label="cst" :reduce="o => o.cst" :searchable="false" :clearable="false">
													<template #option="{ cst, descricao }"><strong class="weight-500">{{ cst }}</strong><br/><small class="font-11 text-wrap">{{ descricao }}</small></template>
													<template v-slot:no-options>Sem resultados</template>
												</v-select>
											</div>
											<div class="col-6 mb-3">
												<label><i class="far fa-percentage color-theme me-1"></i> Alíquota *</label>
												<input type="text" class="form-control text-end" v-model="modal.aliqIpi" />
											</div>
											<div class="col-6 mb-3 mb-sm-0">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Base de cálculo</label>
												<input type="text" class="form-control text-end" v-model="modal.bcIpi" />
											</div>
											<div class="col-sm-6">
												<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Valor</label>
												<input type="text" class="form-control text-end" v-model="modal.valorIpi" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarProdutoTemp(modal, true)">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import produto from '@/components/fiscal/emissao/Produto.vue'
import autoCompleteNCM from '@/components/fiscal/AutoCompleteNCM.vue'
import autoCompleteCFOP from '@/components/fiscal/AutoCompleteCFOP.vue'
import autoCompleteNota from '@/components/fiscal/AutoCompleteNota.vue'
import autoCompleteProduto from '@/components/fiscal/AutoCompleteProduto.vue'
import autoCompleteDestinatario from '@/components/fiscal/AutoCompleteDestinatario.vue'

export default {
	name: 'Emitir_nota',
	data: function () {
      return {
         pesquisa: {
				"naturezaOperacao": null, "cpfCnpjDestinatario": null, "idLojaSaida": null, "idEstoqueSaida": null, "observacoes": null, "produtos": [], "tipoDestinatario": null,
				"chaveReferenciada": null, 'valorTotal': '0.00', 'operacaoInterestadual': false
			},
			pesquisaProduto: {'id': null, 'idProduto': null, 'nome': null, 'quantidade': 1, 'preco': '0.00'},
			modal: {
				"id": null, "origem": null, "aliqIcms": null, "csosn": null, "cstPisCofins": null, "ncm": null, "cest": null, "codBeneficioFiscal": null, "bcIcms": null, "bcPis": null, 
				"nome": null, "codigo": null, "bcCofins": null, "aliqPis": null, "aliqCofins": null, "valorIcms": null, "valorPis": null, "valorCofins": null, "valorDesconto": null, 
				"codigos": [], "idProduto": null, "quantidade": null, "preco": null, "cfop": null, "bcIcmsSt": null, "aliqIcmsSt": null, "valorIcmsSt": null, "cstIPI": null, 
				"bcIpi": null, "aliqIpi": null, "valorIpi": null, "searchNCM": false, "searchCFOP": false
			},
			config: {'estoques': [], 'searchDestino': false}
		}
	},
	computed: {
      ... mapState({
         listaCstPisCofins: state => state.listaCstPisCofins,
			listaOperacoesNfe: state => state.listaOperacoesNfe,
         dadosUsuario: state => state.dadosUsuario,
			listaOrigem: state => state.listaOrigem,
			listaCstIpi: state => state.listaCstIpi,
			listaCSOSN: state => state.dadosUsuario.tipoEmpresa == 'Simples nacional' ? state.listaCSOSN : state.listaCstIcms,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest
      })
   },
   watch: {
		$data: {
			handler: function (value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
			this.buscarTemp()
      },
		pesquisaProduto: {
			handler: function (newVal) {
				this.$store.dispatch('formatValue', {'valor': newVal.quantidade, 'digitos': 4}).then((valor) => {
					this.pesquisaProduto.quantidade = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.preco, 'digitos': 4}).then((valor) => {
					this.pesquisaProduto.preco = valor
				})
			},
			deep: true
      },
		'pesquisa.produtos': {
			handler: function (newVal) {
				this.pesquisa.valorTotal = newVal.reduce((total, p) => total + ((/^[0-9.]+$/.test(p.quantidade) ? parseFloat(p.quantidade) : 0) * (/^[0-9.]+$/.test(p.preco) ? parseFloat(p.preco) : 0)), 0)
			},
			deep: true
      },
		modal: {
			handler: function (newVal) {
				this.$store.dispatch('formatValue', {'valor': newVal.bcIcms, 'digitos': 2}).then((valor) => {
					this.modal.bcIcms = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.bcPis, 'digitos': 2}).then((valor) => {
					this.modal.bcPis = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.bcCofins, 'digitos': 2}).then((valor) => {
					this.modal.bcCofins = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.aliqPis, 'digitos': 2}).then((valor) => {
					this.modal.aliqPis = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.aliqCofins, 'digitos': 2}).then((valor) => {
					this.modal.aliqCofins = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.aliqIcms, 'digitos': 2}).then((valor) => {
					this.modal.aliqIcms = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.valorIcms, 'digitos': 2}).then((valor) => {
					this.modal.valorIcms = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.valorPis, 'digitos': 2}).then((valor) => {
					this.modal.valorPis = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.valorCofins, 'digitos': 2}).then((valor) => {
					this.modal.valorCofins = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.valorDesconto, 'digitos': 2}).then((valor) => {
					this.modal.valorDesconto = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.bcIcmsSt, 'digitos': 2}).then((valor) => {
					this.modal.bcIcmsSt = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.aliqIcmsSt, 'digitos': 2}).then((valor) => {
					this.modal.aliqIcmsSt = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.valorIcmsSt, 'digitos': 2}).then((valor) => {
					this.modal.valorIcmsSt = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.cstIPI, 'digitos': 2}).then((valor) => {
					this.modal.cstIPI = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.bcIpi, 'digitos': 2}).then((valor) => {
					this.modal.bcIpi = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.aliqIpi, 'digitos': 2}).then((valor) => {
					this.modal.aliqIpi = valor
				})
				this.$store.dispatch('formatValue', {'valor': newVal.valorIpi, 'digitos': 2}).then((valor) => {
					this.modal.valorIpi = valor
				})
			},
			deep: true
      }
	},
	components: {
      produto, autoCompleteNCM, autoCompleteCFOP, autoCompleteProduto, autoCompleteNota, autoCompleteDestinatario
   },
	methods: {
		verChavesReferenciadas : function () {
			Swal.fire({
            icon: 'info',
            title: 'Chaves referenciadas',
            html: this.pesquisa.chaveReferenciada.split(',').map(chave => chave.trim()).join('<br>'),
            showDenyButton: true,
				customClass: {
					htmlContainer: 'font-13'
				},
            confirmButtonText: `Fechar`,
            denyButtonText: `Desvincular tudo`

			}).then((result) => {
            if (result.isDenied) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'fiscal/resetChavesReferenciadas'
						
					}).then(() => {
						this.buscarTemp()

						this.$toast.fire({
							icon: 'success',
							title: 'Chaves desvinculadas'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else if (error.response.status == 400) {
                        Swal.fire({
                           icon: 'error',
                           text: error.response.data
                        });
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		},
		abrirModalProduto : function (produto) {
			this.modal = {
				"id": produto.id, 
				"cfop": produto.cfop, 
				"origem": produto.origem, 
				"aliqIcms": produto.aliqIcms == null ? "0.00" : parseFloat(produto.aliqIcms).toFixed(2), 
				"csosn": produto.csosn, 
				"cstPisCofins": produto.cstPisCofins, 
				"ncm": produto.ncm, 
				"cest": produto.cest, 
				"codBeneficioFiscal": produto.codBeneficioFiscal, 
				"bcIcms": produto.bcIcms == null ? "0.00" : parseFloat(produto.bcIcms).toFixed(2), 
				"bcPis": produto.bcPis == null ? "0.00" : parseFloat(produto.bcPis).toFixed(2), 
				"nome": produto.nome,
				"codigo": produto.codigo,
				"bcCofins": produto.bcCofins == null ? "0.00" : parseFloat(produto.bcCofins).toFixed(2), 
				"aliqPis": produto.aliqPis == null ? "0.00" : parseFloat(produto.aliqPis).toFixed(2), 
				"aliqCofins": produto.aliqCofins == null ? "0.00" : parseFloat(produto.aliqCofins).toFixed(2), 
				"valorIcms": produto.valorIcms == null ? "0.00" : parseFloat(produto.valorIcms).toFixed(2), 
				"valorPis": produto.valorPis == null ? "0.00" : parseFloat(produto.valorPis).toFixed(2), 
				"valorCofins": produto.valorCofins == null ? "0.00" : parseFloat(produto.valorCofins).toFixed(2), 
				"valorDesconto": produto.valorDesconto == null ? "0.00" : parseFloat(produto.valorDesconto).toFixed(2), 
				"bcIcmsSt": produto.bcIcmsSt == null ? "0.00" : parseFloat(produto.bcIcmsSt).toFixed(2), 
				"aliqIcmsSt": produto.aliqIcmsSt == null ? "0.00" : parseFloat(produto.aliqIcmsSt).toFixed(2), 
				"valorIcmsSt": produto.valorIcmsSt == null ? "0.00" : parseFloat(produto.valorIcmsSt).toFixed(2), 
				"cstIPI": produto.cstIPI, 
				"bcIpi": produto.bcIpi == null ? "0.00" : parseFloat(produto.bcIpi).toFixed(2), 
				"aliqIpi": produto.aliqIpi == null ? "0.00" : parseFloat(produto.aliqIpi).toFixed(2), 
				"valorIpi": produto.valorIpi == null ? "0.00" : parseFloat(produto.valorIpi).toFixed(2), 
				"codigos": produto.codigos == null ? [] : String(produto.codigos).split(","),
				"idProduto": produto.idProduto, 
				"quantidade": produto.quantidade, 
				"preco": produto.preco,
				"searchNCM": false,
				"searchCFOP": false
			}

			$('#modalProduto').modal('show')
		},
		buscarEstoques : function (idLoja) {
			/* eslint-disable-next-line no-unused-vars */
			return new Promise((resolve, reject) => {
				let loja = this.dadosUsuario.usuarioLojas.find(l => l.id == idLoja)
	
				if (loja != null) {
					this.config.estoques = loja.estoques
					
				} else {
					this.config.estoques = []
					this.pesquisa.idLojaSaida = null
					this.pesquisa.idEstoqueSaida = null
				}
	
				this.salvarTemp()
				resolve()
			})
		},
		buscarTemp : function () {			
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa, this.$options.data.apply(this).pesquisa)

			this.$axios({
				method: 'get',
				url: this.urlRest +'fiscal/getNotaTemp'
				
			}).then(response => {
				let lojaSaida = this.dadosUsuario.usuarioLojas.find(l => l.id == response.data.idLojaSaida)
				let estoqueSaida = lojaSaida == null ? null : lojaSaida.estoques.find(e => e.id == response.data.idEstoqueSaida)

				this.buscarEstoques(lojaSaida == null ? null : lojaSaida.id).then(() => {
					this.config.searchDestino = lojaSaida == null;
					this.pesquisa = {
						"idLojaSaida": lojaSaida == null ? null : lojaSaida.id,
						"idEstoqueSaida": estoqueSaida == null ? null : estoqueSaida.id,
						"cpfCnpjDestinatario": response.data.cpfCnpjDestinatario,
						"observacoes": response.data.observacoes,
						"produtos": response.data.produtos,
						"chaveReferenciada": response.data.chaveReferenciada, 
						"tipoDestinatario": null,
						'valorTotal': 0,
						"naturezaOperacao": this.listaOperacoesNfe.find(o => o.naturezaOperacao == response.data.naturezaOperacao),
						'operacaoInterestadual': response.data.operacaoInterestadual
					}
				})
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
		addNotaTemp : function (result) {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/addNotaTemp',
				headers: {'Content-Type': 'application/json'},
				params: {
					id: result.id
				}
			}).then(() => {
				this.buscarTemp()

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		setDestinatarioTemp : function (result) {
			this.pesquisa.cpfCnpjDestinatario = result.cpfCnpj; 
			this.config.searchDestino = false;
			this.salvarTemp()
		},
		salvarTemp : function () {
			if (this.pesquisa.naturezaOperacao) {
				this.$store.dispatch('alternarTelaCarregamento', true)

				let envio = JSON.parse(JSON.stringify(this.pesquisa))
				envio.tipoDestinatario = envio.naturezaOperacao.tipoDestinatario
				envio.naturezaOperacao = envio.naturezaOperacao.naturezaOperacao

				this.$axios({
					method: 'post',
					url: this.urlRest +'fiscal/saveNotaTemp',
					headers: {'Content-Type': 'application/json'},
					data: envio

				}).finally(() => {
					this.$store.dispatch('alternarTelaCarregamento', false)
				});
			}
		},
		setProdutoTemp : function (result) {
			this.pesquisaProduto = {'id': null, 'idProduto': result.id, 'nome': result.nome, 'quantidade': 1, 'preco': result.custo == null ? "0.00" : parseFloat(result.custo).toFixed(2)}

			setTimeout(() => {
				this.salvarProdutoTemp(this.pesquisaProduto, false)
			}, 50);
		},
		salvarProdutoTemp : function (produto, impostos) {
			if (produto == null) produto = this.pesquisaProduto;

			if (produto.idProduto == null) return;

			if (impostos && (this.modal.codigo == null || String(this.modal.codigo).trim().length == 0 || this.modal.cfop == null || String(this.modal.cfop).trim().length == 0 || 
				this.modal.valorDesconto == null || String(this.modal.valorDesconto).trim().length == 0 || this.modal.ncm == null || String(this.modal.ncm).trim().length == 0 || 
				this.modal.origem == null || String(this.modal.origem).trim().length == 0 || this.modal.csosn == null || String(this.modal.csosn).trim().length == 0 || 
				this.modal.aliqIcms == null || String(this.modal.aliqIcms).trim().length == 0 || this.modal.bcIcms == null || String(this.modal.bcIcms).trim().length == 0 || 
				this.modal.valorIcms == null || String(this.modal.valorIcms).trim().length == 0 || this.modal.cstPisCofins == null || String(this.modal.cstPisCofins).trim().length == 0 || 
				this.modal.aliqPis == null || String(this.modal.aliqPis).trim().length == 0 || this.modal.bcPis == null || String(this.modal.bcPis).trim().length == 0 || 
				this.modal.valorPis == null || String(this.modal.valorPis).trim().length == 0 || this.modal.aliqCofins == null || String(this.modal.aliqCofins).trim().length == 0 || 
				this.modal.bcCofins == null || String(this.modal.bcCofins).trim().length == 0 || this.modal.valorCofins == null || String(this.modal.valorCofins).trim().length == 0)) {
				
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			} else if (impostos && (!/^[0-9.]+$/.test(produto.quantidade) || !/^[0-9.]+$/.test(produto.preco))) {
				this.$toast.fire({
					icon: 'error',
					title: 'Quantidade/preço unitário inválido'
				});

				return
			}

			if (produto.id == null || impostos) this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'fiscal/saveNotaProdutoTemp',
				headers: {'Content-Type': 'application/json'},
				data: produto

			}).then(() => {
				Object.assign(this.$data.pesquisaProduto, this.$options.data.apply(this).pesquisaProduto)

				if (produto.id == null || impostos) this.buscarTemp()

				if (impostos) $('#modalProduto').modal('hide')

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		excluirProdutoTemp : function (index) {
			this.pesquisa.produtos.splice(index, 1)
		},
		excluirTemp : function () {
			Swal.fire({
            icon: 'warning',
            title: 'Descartar nota?',
            text: 'Todas informações preenchidas até o momento serão descartadas.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
				
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'fiscal/deleteNotaTemp'
						
					}).then(() => {
						this.buscarTemp()
		
						this.$toast.fire({
							icon: 'success',
							title: 'Nota descartada!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		},
		emitirNota : function () {
			if (this.pesquisa.idLojaSaida == null || this.pesquisa.idEstoqueSaida == null || this.pesquisa.cpfCnpjDestinatario == null || this.pesquisa.produtos.length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			Swal.fire({
            icon: 'warning',
            title: 'Emitir nota?',
            text: 'Essa ação é irreversível, caso necessário, adicione observações para registro abaixo',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`,
				input: 'textarea',
				inputPlaceholder: 'Observações',
            inputValidator: () => {
               if (this.pesquisa.produtos.filter(p => !/^[0-9.]+$/.test(p.quantidade) || !/^[0-9.]+$/.test(p.preco)).length > 0) {
                  return 'Existem produtos com quantidade/preço inválidos!'
               }
            }
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'fiscal/emitirNota',
						headers: {'Content-Type': 'application/json'},
						data: {'observacoes': result.value}
						
					}).then((response) => {
						this.buscarTemp()

						Swal.fire({
							icon: 'success',
							title: 'Nota emitida!',
							text: 'Imprimir comprovante?',
							showCancelButton: true,
							confirmButtonText: 'Sim',
							cancelButtonText: `Não`
							
						}).then((result) => {
							if (result.isConfirmed) {
								this.$store.dispatch('baixarNF', response.data.chave)
							}
						})
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else if (error.response.status == 400) {
                        Swal.fire({
                           icon: 'error',
                           text: error.response.data
                        });
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
		}
	},
   mounted() {
		this.$store.dispatch('getOperacoesNfe')
		this.buscarTemp()
   }
}

</script>

<style scoped>

#emissao-tab .card .row button {
	line-height: 1;
	padding: 12px 6px;
	text-align: center;
	display: block;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 12px;
	width: 100%;
	border-radius: 3px;
}

@media (min-width: 992px) {
   #emissao-tab {
      width: 250px;
   }
}

@media (min-width: 1400px) {
   #emissao-tab {
      width: 305px;
   }
}

</style>